function About() {
    return (
        <div className="app-about" id="aboutus">
            <h1 className="title-txt">About Us</h1>
            <p className="title-desc">We are running the business around 4 years in the namakkal.</p>
            <p className="title-desc">We are giving home appliance, furniture and customer support.</p>
            <p className="title-desc">This state is only due to my customer support.</p>
        </div>
    )
}
export default About;