import Butterfly from "../assets/img/brands/butterfly.png";
import Prestige from "../assets/img/brands/prestige.png";
import Crompton from "../assets/img/brands/crompton.png";
import Havells from "../assets/img/brands/havells.png";
import Ktech from "../assets/img/brands/ktech.png";
import Preethi from "../assets/img/brands/preethi.png";
import Vidiem from "../assets/img/brands/vidiem.png";
import Usha from "../assets/img/brands/usha.png";
import Milton from "../assets/img/brands/milton.png";
import Vguard from "../assets/img/brands/vguard.jpg";
function Brands() {
    return (
        <div className="app-brands" id="brands">
            <h1 className="title-txt">Our Brands</h1>
            <div className="app-brand-wrap">
                <img src={Butterfly} alt="Butterfly" />
            </div>
            <div className="app-brand-wrap">
                <img src={Prestige} alt="Prestige" />
            </div>
            <div className="app-brand-wrap">
                <img src={Crompton} alt="Crompton" />
            </div>
            <div className="app-brand-wrap">
                <img src={Havells} alt="Havells" />
            </div>
            <div className="app-brand-wrap">
                <img src={Preethi} alt="Preethi" />
            </div>
            <div className="app-brand-wrap">
                <img src={Vidiem} alt="Vidiem" />
            </div>
            <div className="app-brand-wrap">
                <img src={Ktech} alt="Ktech" />
            </div>
            <div className="app-brand-wrap">
                <img src={Usha} alt="Usha" />
            </div>
            <div className="app-brand-wrap">
                <img src={Milton} alt="Milton" style={{ background: "#ea2127", padding: 5 }} />
            </div>
            <div className="app-brand-wrap">
                <img src={Vguard} alt="V Guard" />
            </div>
            <div className="app-brand-wrap">
                <p>Sowrna Lakshmi Grinder</p>
            </div>
            <div className="app-brand-wrap">
                <p>Lakshmi Tawa</p>
            </div>
            <div className="app-brand-wrap">
                <p>Cirrus Bed</p>
            </div>
            <div className="app-brand-wrap">
                <p>VV National Chair</p>
            </div>
            <div className="app-brand-wrap">
                <p style={{background:"rgb(55, 51, 105)"}}>Surya Flame</p>
            </div>
            <div className="app-brand-wrap">
                <p>Raj Surya Flame</p>
            </div>
        </div>
    )
}
export default Brands;