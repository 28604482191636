import { useEffect } from "react";
import Banner1 from "../assets/img/banners/banner1.jpg";
import Banner2 from "../assets/img/banners/banner2.jpeg";
import Banner3 from "../assets/img/banners/banner3.jpg";

function Banner() {


    useEffect(() => {
        let slideIndex = 0;
        showSlides(slideIndex);
    }, [])

    const showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        n++;
        if (n > slides.length) { n = 1 }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[n - 1].style.display = "block";
        dots[n - 1].className += " active";
        setTimeout(() => {
            showSlides(n)
        }, 2000);
    }
    return (
        <div className="app-banner" id="home">
            <div className="slideshow-container">

                <div className="mySlides fade">
                    <img src={Banner1} style={{ "width": "100%", "height": "calc(100vh - 100px)" }} />
                </div>

                <div className="mySlides fade">
                    <img src={Banner2} style={{ "width": "100%", "height": "calc(100vh - 100px)" }} />
                </div>

                <div className="mySlides fade">
                    <img src={Banner3} style={{ "width": "100%", "height": "calc(100vh - 100px)" }} />
                </div>

            </div>

            <div className="dot-wrap">
                <span className="dot" onClick={() => { showSlides(1) }}></span>
                <span className="dot" onClick={() => { showSlides(2) }}></span>
                <span className="dot" onClick={() => { showSlides(3) }}></span>
            </div>
        </div>
    )
}
export default Banner;