import Header from './components/header';
import Banner from './components/banner';
import About from './components/about';
import Products from './components/product';
import Contact from "./components/contact";
import Brands from './components/brands';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <About />
      <Products />
      <Brands />
      <Contact />
    </div>
  );
}

export default App;
