import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
function ProductDetails(props) {
    const [productZoomPreview, setProductZoomPreview] = useState('0% 0%')
    const showSlides = (n, cl) => {
        let i;
        let slides = document.getElementsByClassName(cl);
        let dots = document.getElementsByClassName(`dot-${cl}`);
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        n++;
        if (n > slides.length) { n = 1 }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[n - 1].style.display = "block";
        dots[n - 1].className += " active";
    }
    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setProductZoomPreview(`${x}% ${y}%`)
    }
    return (

        (props.productData && props.productData.products && props.productData.products.length) ? <div className="app-product-details">{
            props.productData.products.map((item, i) => {
                return <div className="app-product-cont fade-in-image" key={i}>
                    {item.discount ? <div className='p-offer-section'>
                        <LocalOfferIcon />
                        <p>{item.discount}</p>
                    </div> : null}
                    {item.brand ? <div className='p-brand-section' style={{ background: item.brand === "KTech" ? "#3BBCC4" : item.brand === "Crompton" ? "#004c86" : item.brand === "Surya Flame" ? "#373369" : item.brand === "USHA" ? "#939498" : "#e33530" }}>
                        <p>{item.brand}</p>
                    </div> : <div className='p-brand-section'><StarIcon style={{ color: "#5ac0e8" }} /></div>}
                    {item.icon ? <div className='p-icon-section'>
                        {item.id ? <React.Fragment>
                            <div className="slideshow-container" style={{ height: "250px" }}>
                                {item.icon.map((icon, ii) => {
                                    return <div className={`${item.id} fade product-slider`} style={{ display: ii === 0 ? "block" : "none" }} key={ii}>
                                        {/* <figure onMouseMove={handleMouseMove} style={{
                                            backgroundImage: `url(${icon})`,
                                            backgroundPosition: productZoomPreview
                                        }}> */}
                                        <img src={icon} alt={item.name} />
                                        {/* </figure> */}
                                    </div>
                                })}
                                <div className="dot-wrap">
                                    {item.icon.map((icon, ii) => {
                                        return <span className={`dot-${item.id} dot-product ${ii === 0 ? "active" : ""}`} onClick={() => { showSlides(ii, item.id) }}></span>
                                    })}
                                </div>
                            </div>


                        </React.Fragment> : <img src={item.icon} alt={item.name} />
                            // <figure onMouseMove={handleMouseMove} style={{
                            //     backgroundImage: `url(${item.icon})`,
                            //     backgroundPosition: productZoomPreview
                            // }}
                            // >
                            //     <img src={item.icon} alt={item.name} />
                            // </figure>
                        }
                    </div> : null}
                    <h3 className='p-name'>{item.name}</h3>
                    {item.watts ? <div className='p-watts-section'>
                        <p>{item.watts}</p>
                        {item.warranty ? <div className='p-warrenty-section'>
                            <LocalPoliceIcon /> <span>{item.warranty}</span>
                        </div> : null}
                    </div> : null}
                    {item.availableColors ? <div className='p-color-section'>
                        <span>Available Colors:</span>
                        {item.availableColors.map((ac, ai) => {
                            return <p style={{ background: ac }}></p>
                        })}
                    </div> : null}
                    <div className='p-price-section'>
                        {item.mrp ? <p className='mrp'>MRP: {item.mrp}</p> : null}
                        <h3 className='price'>{item.price}</h3>
                    </div>

                </div>
            })} </div> : <div className="app-contact">
            <h1 className="title-txt">We are working this page. The Products are available. So Please Contact Us</h1>
            <div className='address-with-icon'>
                <div className='address-icon'><LocationOnIcon /></div>
                <div className='address-txt'>
                    <p>Senthamangalam Road,</p>
                    <p>Nearby Kalaiyarasi Hospital</p>
                    <p>Budan Santhai, Namakkal (DT).</p>
                </div>
            </div>
            <div className='address-with-icon'>
                <div className='address-icon'><PhoneIcon /></div>
                <div className='address-txt'>
                    <p>97912 90312,</p>
                    <p>94864 88321.</p>
                    <p style={{ opacity: 0 }}>Budan Santhai, Namakkal (DT).</p>
                </div>
            </div>
        </div>
    )
}
export default ProductDetails;