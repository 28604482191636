import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
function Contact() {
    return (
        <div className="app-contact" id="contactus">
            <h1 className="title-txt">Contact Us</h1>
            <div className='address-with-icon'>
                <div className='address-icon'><LocationOnIcon /></div>
                <div className='address-txt'>
                    <p>Senthamangalam Road,</p>
                    <p>Nearby Kalaiyarasi Hospital</p>
                    <p>Budan Santhai, Namakkal (DT).</p>
                </div>
            </div>
            <div className='address-with-icon'>
                <div className='address-icon'><PhoneIcon /></div>
                <div className='address-txt'>
                    <p>97912 90312,</p>
                    <p>94864 88321.</p>
                    <p style={{opacity:0}}>Budan Santhai, Namakkal (DT).</p>
                </div>
            </div>
            <div className='address-with-icon'>
                <div className='address-icon' style={{textAlign: "center"}}>Copyright © 2023 <a href="/">www.reddyammanagencies.com</a>, <span className='mobile-d-block'>All rights reserved</span> </div>
            </div>
        </div>
    );
}

export default Contact;
