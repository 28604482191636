import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import Cooker from "../assets/img/icons/coocker.jpeg";
import Grinder from "../assets/img/icons/grinder.jpg";
import Mixer from "../assets/img/icons/mixer.jpeg";
import CeilingFan from "../assets/img/icons/ceiling-fan.jpeg";
import TableFan from "../assets/img/icons/table-fan.jpeg";
import WaterHeater from "../assets/img/icons/heater.jpeg";
import IronBox from "../assets/img/icons/iron-box.jpeg";
import Induction from "../assets/img/icons/induction.jpeg";
import Stove from "../assets/img/icons/stove.jpg";
import ElectricKettle from "../assets/img/icons/kettle.jpg";
import HairDryer from "../assets/img/icons/hair-dryer.jpeg";
import BeardTrimmer from "../assets/img/icons/trimmer.jpeg";
import Bero from "../assets/img/icons/bero.jpeg";
import Cot from "../assets/img/icons/cot.jpeg";
import Bed from "../assets/img/icons/bed.jpeg";
import Flask from "../assets/img/icons/flask.jpg";
import WaterBottle from "../assets/img/icons/waterbottle.jpg";
import NonStickTawa from "../assets/img/icons/non-stick-tawa.jpg";
import StudyTable from "../assets/img/icons/table.jpg";
import Chair from "../assets/img/icons/chair.jpg";
import DressingTable from "../assets/img/icons/dressing-table.jpeg";
import SteelRack from "../assets/img/icons/steel-rack.jpeg";
import TVStand from "../assets/img/icons/tv-stand.jpg";

//Ironbox
import IBP1 from "../assets/img/products/ironbox/p1.jpg";
import IBP2 from "../assets/img/products/ironbox/p2.jpg";
import IBP3 from "../assets/img/products/ironbox/p3.jpg";
import IBP4 from "../assets/img/products/ironbox/p4.jpg";
import IBP5 from "../assets/img/products/ironbox/p5.jpeg";
import IBP6 from "../assets/img/products/ironbox/p6.jpeg";
import IBP7 from "../assets/img/products/ironbox/p7.jpeg";
//Mixer
import MP1 from "../assets/img/products/mixer/p1.jpeg";
import MP2 from "../assets/img/products/mixer/p2.jpeg";
import MP3 from "../assets/img/products/mixer/p3.jpg";
import MP4 from "../assets/img/products/mixer/p4.jpg";
import MP5 from "../assets/img/products/mixer/p5.jpeg";
import MP6 from "../assets/img/products/mixer/p6.jpeg";
import MP7 from "../assets/img/products/mixer/p7.png";
import MP8 from "../assets/img/products/mixer/p8.jpg";
import MP9 from "../assets/img/products/mixer/p9.jpg";
import MP10 from "../assets/img/products/mixer/p10.jpg";
import MP11 from "../assets/img/products/mixer/p11.jpg";
import MP12 from "../assets/img/products/mixer/p12.jpg";
//Grinder
import GMP1 from "../assets/img/products/mixer/gp1.jpeg";
import GMP2 from "../assets/img/products/mixer/gp2.jpeg";
import GMP3 from "../assets/img/products/mixer/gp3.jpeg";
import GMP4 from "../assets/img/products/mixer/gp4.jpg";
import GMP4Back from "../assets/img/products/mixer/gp4-back.jpg";
import GMP4Inner from "../assets/img/products/mixer/gp4-inner.jpg";
import GMP5 from "../assets/img/products/mixer/gp5.jpg";
//Insta Cook
import INSP1 from "../assets/img/products/insta-cook/p1.jpg";
import INSP2 from "../assets/img/products/insta-cook/p2.jpg";
import INSP3 from "../assets/img/products/insta-cook/p3.jpg";
import INSP4 from "../assets/img/products/insta-cook/p4.jpg";
import INSP5 from "../assets/img/products/insta-cook/p5.jpeg";
import INSP6 from "../assets/img/products/insta-cook/p6.jpg";
//Hair Dryer
import HDP1 from "../assets/img/products/hd/p1.jpg";
//Hair Dryer
import WHP1 from "../assets/img/products/wh/p1.jpg";
import WHP2 from "../assets/img/products/wh/p2.jpg";
import WHP3 from "../assets/img/products/wh/p3.jpeg";
//Fan
import FP1 from "../assets/img/products/fan/p1.jpg";
import FP2 from "../assets/img/products/fan/p2.jpg";
import FP21 from "../assets/img/products/fan/p2-1.jpg";
import FP22 from "../assets/img/products/fan/p2-2.jpg";
import FP23 from "../assets/img/products/fan/p2-3.jpg";
import FP3 from "../assets/img/products/fan/p3.jpg";
import FP4 from "../assets/img/products/fan/p4.jpg";
import FP5 from "../assets/img/products/fan/p5.jpg";
import FP6 from "../assets/img/products/fan/p6.jpg";
import FP7 from "../assets/img/products/fan/p7.jpg";
//Table Fan
import TFP1 from "../assets/img/products/tablefan/p1.jpg";
import TFP2 from "../assets/img/products/tablefan/p2.jpg";
import TFP3 from "../assets/img/products/tablefan/p3.jpg";
import TFP4 from "../assets/img/products/tablefan/p4.jpg";
import TFP5 from "../assets/img/products/tablefan/p5.jpg";
import TFP6 from "../assets/img/products/tablefan/p6.jpg";
//Coocker
import CP1 from "../assets/img/products/cooker/p1.jpg";
import CP2 from "../assets/img/products/cooker/p2.jpg";
import CP3 from "../assets/img/products/cooker/p3.jpg";
import CP4 from "../assets/img/products/cooker/p4.jpg";
import CP5 from "../assets/img/products/cooker/p5.jpg";
import CP6 from "../assets/img/products/cooker/p6.jpg";
import CPButterflyBottom from "../assets/img/products/cooker/butterflyback.jpg";
import CPButterflyInner from "../assets/img/products/cooker/butterflyinner.jpg";
import CPButterflyTop from "../assets/img/products/cooker/butterflytop.jpg";
import CPCapacity from "../assets/img/products/cooker/coocker-capacity.jpg";
import CPPrestigeBottom from "../assets/img/products/cooker/prestigebottom.jpg";
import CPPrestigeDD from "../assets/img/products/cooker/prestigedodont.jpg";
import CPPrestigeInnerTop from "../assets/img/products/cooker/prestigetopinner.jpg";
//Bero
import BP1 from "../assets/img/products/bero/p1.jpeg";
import BP2 from "../assets/img/products/bero/p2.jpeg";
import BP3 from "../assets/img/products/bero/p3.jpeg";
import BP31 from "../assets/img/products/bero/p3-1.jpeg";
//Dressing Table
import DTP1 from "../assets/img/products/dt/p1.jpeg";
import DTP2 from "../assets/img/products/dt/p2.jpeg";
import DTP3 from "../assets/img/products/dt/p3.jpeg";
import DTP4 from "../assets/img/products/dt/p4.jpeg";
//Stove
import StoveP1 from "../assets/img/products/stove/p1.jpeg";
import StoveP2 from "../assets/img/products/stove/p2.jpg";
import StoveP3 from "../assets/img/products/stove/p3.jpg";
import StoveP4 from "../assets/img/products/stove/p4.jpg";
import StoveP5 from "../assets/img/products/stove/p5.jpg";
import StoveP6 from "../assets/img/products/stove/p6.jpg";
import StoveP7 from "../assets/img/products/stove/p7.jpg";
import StoveP8 from "../assets/img/products/stove/p8.jpg";
import StoveP9 from "../assets/img/products/stove/p9.jpg";
import StoveP10 from "../assets/img/products/stove/p10.jpg";
import StoveP11 from "../assets/img/products/stove/p11.jpg";
import StoveP12 from "../assets/img/products/stove/p12.jpg";
import StoveP13 from "../assets/img/products/stove/p13.jpg";
//Tawa
import TawaP1 from "../assets/img/products/tawa/p1.jpg";
import TawaP2 from "../assets/img/products/tawa/p2.jpg";
import TawaPOuter from "../assets/img/products/tawa/p2-outer.jpg";
import TawaP3 from "../assets/img/products/tawa/p3.jpg";
import TawaPBox from "../assets/img/products/tawa/p3-box.jpg";
//Table
import TP1 from "../assets/img/products/table/p1.jpg";
import TP11 from "../assets/img/products/table/p1-1.jpg";
import TP2 from "../assets/img/products/table/p2.jpg";
import TP3 from "../assets/img/products/table/p3.jpg";
import TP4 from "../assets/img/products/table/p4.jpeg";
import TP5 from "../assets/img/products/table/p5.jpg";
//Chair
import CP11 from "../assets/img/products/chair/p1-1.jpg";
import CP12 from "../assets/img/products/chair/p1-2.jpg";
import CP13 from "../assets/img/products/chair/p1-3.jpg";
import CP14 from "../assets/img/products/chair/p1-4.jpg";
import CHP2 from "../assets/img/products/chair/p2.jpg";
import CHP3 from "../assets/img/products/chair/p3.jpg";
import CHP4 from "../assets/img/products/chair/p4.jpg";
//TV Stand
import TVP1 from "../assets/img/products/tv-stand/p1.jpg";
import TVP2 from "../assets/img/products/tv-stand/p2.jpg";
import SRP1 from "../assets/img/products/steel-rack-p1.jpg";
//Cot
import COTP1 from "../assets/img/products/hd/cot-p1.jpeg";
import COTP21 from "../assets/img/products/hd/cot-p21.jpeg";
import COTP22 from "../assets/img/products/hd/cot-p22.jpeg";
import COTP23 from "../assets/img/products/hd/cot-p23.jpeg";
import COTP24 from "../assets/img/products/hd/cot-p24.jpeg";
//Air Cooler
import ACP1 from "../assets/img/products/air-cooler/p1.jpeg";
import ACP2 from "../assets/img/products/air-cooler/p2.jpeg";
import ACP3 from "../assets/img/products/air-cooler/p3.jpeg";
import ACP4 from "../assets/img/products/air-cooler/p4.jpeg";

import EKP1 from "../assets/img/products/kettle-p1.jpeg";

//Flask
import FLASKP11 from "../assets/img/products/flask/p1-1.jpg";
import FLASKP12 from "../assets/img/products/flask/p1-2.jpg";
import FLASKP13 from "../assets/img/products/flask/p1-3.jpg";
import FLASKP14 from "../assets/img/products/flask/p1-4.jpg";
import FLASKP15 from "../assets/img/products/flask/p1-5.jpg";
import FLASKP21 from "../assets/img/products/flask/p2-1.jpg";
import FLASKP22 from "../assets/img/products/flask/p2-2.jpg";
import FLASKP23 from "../assets/img/products/flask/p2-3.jpg";
import FLASKP24 from "../assets/img/products/flask/p2-4.jpg";
import FLASKP25 from "../assets/img/products/flask/p2-5.jpg";



import ProductDetails from "./productDetails";
function Products() {

    const products = [
        {
            "name": "Cooker",
            "icon": CP6,
            "products": [{
                "name": "Cooker",
                "icon": Cooker,
                "brand": "KTech",
                "watts": "Capacity 3L",
                "discount": "27%",
                "mrp": "₹ 1,079.00",
                "price": "₹ 787.00"
            }, {
                "name": "Dripless Cooker",
                "icon": CP1,
                "brand": "KTech",
                "watts": "Capacity 3L",
                "discount": "40%",
                "mrp": "₹ 1,650.00",
                "price": "₹ 990.00"
            }, {
                "name": "Dripless Cooker",
                "icon": CP1,
                "brand": "KTech",
                "watts": "Capacity 5L",
                "discount": "20%",
                "mrp": "₹ 1,310.00",
                "price": "₹ 1,048.00"
            }, {
                "name": "Dripless Cooker",
                "icon": Cooker,
                "brand": "KTech",
                "watts": "Capacity 5L",
                "discount": "35%",
                "mrp": "₹ 1,995.00",
                "price": "₹ 1,296.00"
            }, {
                "name": "Cooker",
                "icon": Cooker,
                "brand": "KTech",
                "watts": "Capacity 7.5L",
                "discount": "15%",
                "mrp": "₹ 1,559.00",
                "price": "₹ 1,325.00"
            }, {
                "name": "Cooker",
                "icon": Cooker,
                "brand": "KTech",
                "watts": "Capacity 10L",
                "discount": "10%",
                "mrp": "₹ 1,729.00",
                "price": "₹ 1,556.00"
            }, {
                "name": "Cooker",
                "icon": Cooker,
                "brand": "KTech",
                "watts": "Capacity 12L",
                "discount": "10%",
                "mrp": "₹ 1,839.00",
                "price": "₹ 1,655.00"
            }, {
                "name": "Butterfly Friendly Aluminium Pressure Coocker",
                "icon": [CP2, CPButterflyTop, CPButterflyInner, CPButterflyBottom, CPCapacity],
                "brand": "Butterfly",
                "watts": "Capacity 3L",
                "discount": "30%",
                "mrp": "₹ 1,899.00",
                "price": "₹ 1,329.00",
                "id": "CPB1"
            }, {
                "name": "Butterfly Cordial Non Induction Aluminium Pressure Coocker with Outer Lid, Silver",
                "icon": [CP3, CPButterflyTop, CPButterflyInner, CPButterflyBottom, CPCapacity],
                "brand": "Butterfly",
                "watts": "Capacity 5L",
                "discount": "25%",
                "mrp": "₹ 2,435.00",
                "price": "₹ 1,826.00",
                "id": "CPB2"
            }, {
                "name": "Butterfly Friendly Pressure Coocker",
                "icon": [CP5, CPButterflyTop, CPButterflyInner, CPButterflyBottom, CPCapacity],
                "brand": "Butterfly",
                "watts": "Capacity 5.5L",
                "discount": "20%",
                "mrp": "₹ 3,029.00",
                "price": "₹ 2,423.00",
                "id": "CPB3"
            }, {
                "name": "Butterfly Friendly Pressure Coocker",
                "icon": [CP4, CPButterflyTop, CPButterflyInner, CPButterflyBottom, CPCapacity],
                "brand": "Butterfly",
                "watts": "Capacity 7.5L",
                "discount": "25%",
                "mrp": "₹ 3,402.00",
                "price": "₹ 2,551.00",
                "id": "CPB4"
            }, {
                "name": "Prestige Pressure Coocker",
                "icon": [CP6, CPPrestigeInnerTop, CPPrestigeBottom, CPPrestigeDD, CPCapacity],
                "brand": "Prestige",
                "watts": "Capacity 3L",
                "discount": "10%",
                "mrp": "₹ 1,640.00",
                "price": "₹ 1,476.00",
                "id": "CPP1"
            }, {
                "name": "Prestige Pressure Coocker",
                "icon": [CP6, CPPrestigeInnerTop, CPPrestigeBottom, CPPrestigeDD, CPCapacity],
                "brand": "Prestige",
                "watts": "Capacity 5L",
                "discount": "11%",
                "mrp": "₹ 2,230.00",
                "price": "₹ 1,984.00",
                "id": "CPP2"
            }, {
                "name": "Prestige Pressure Coocker",
                "icon": [CP6, CPPrestigeInnerTop, CPPrestigeBottom, CPPrestigeDD, CPCapacity],
                "brand": "Prestige",
                "watts": "Capacity 7.5L",
                "discount": "10%",
                "mrp": "₹ 3,070.00",
                "price": "₹ 2,763.00",
                "id": "CPP3"
            }, {
                "name": "Prestige Pressure Coocker",
                "icon": [CP6, CPPrestigeInnerTop, CPPrestigeBottom, CPPrestigeDD, CPCapacity],
                "brand": "Prestige",
                "watts": "Capacity 10L",
                "discount": "8%",
                "mrp": "₹ 3,600.00",
                "price": "₹ 3,312.00",
                "id": "CPP4"
            }, {
                "name": "Prestige Pressure Coocker",
                "icon": [CP6, CPPrestigeInnerTop, CPPrestigeBottom, CPPrestigeDD, CPCapacity],
                "brand": "Prestige",
                "watts": "Capacity 12L",
                "discount": "10%",
                "mrp": "₹ 3,920.00",
                "price": "₹ 3,528.00",
                "id": "CPP5"
            }
            ]
        },
        {
            "name": "Grinder",
            "icon": Grinder,
            "products": [{
                "name": "Ajai Wed Grinder",
                "icon": Grinder,
                "brand": "HAVELLS",
                "watts": "150W 2L",
                "discount": "25%",
                "mrp": "₹ 9,300.00",
                "price": "₹ 6,975.00"
            }, {
                "name": "Sowrna Lakshmi",
                "icon": GMP1,
                "discount": "23%",
                "mrp": "₹ 4,500.00",
                "price": "₹ 3,465.00"
            }, {
                "name": "Basic",
                "icon": GMP2,
                "discount": "10%",
                "mrp": "₹ 6,500.00",
                "price": "₹ 5,850.00"
            }, {
                "name": "Macel Classic",
                "icon": GMP3,
                "discount": "21%",
                "mrp": "₹ 7,500.00",
                "price": "₹ 5,925.00"
            }, {
                "name": "Elgi Ultra Bigg+ Table Top Wet Grinder",
                "icon": [GMP4, GMP4Back, GMP4Inner],
                "discount": "10%",
                "mrp": "₹ 10,590.00",
                "price": "₹ 9,531.00",
                "id": "gp4"
            }, {
                "name": "Butterfly Rhino Wet Grinder",
                "icon": GMP5,
                "discount": "33%",
                "watts": "2LR",
                "mrp": "₹ 7,455.00",
                "price": "₹ 4,994.00"
            }]
        },
        {
            "name": "Non Stick Tawa",
            "icon": NonStickTawa,
            "products": [{
                "name": "Prestige Tawa",
                "icon": [TawaP3, TawaPBox],
                "brand": "Prestige",
                "watts": "0T 300MM",
                "discount": "8%",
                "mrp": "₹ 1,400 .00",
                "price": "₹ 1,288.00",
                "id": "tawa3"
            }, {
                "name": "Prestige Tawa",
                "icon": [TawaPBox, TawaP3],
                "brand": "Prestige",
                "watts": "0T 250MM",
                "discount": "8%",
                "mrp": "₹ 1,000.00",
                "price": "₹ 920.00",
                "id": "tawa4"
            }, {
                "name": "Prestige Tawa",
                "icon": [TawaP3, TawaPBox],
                "brand": "Prestige",
                "watts": "0T 275MM",
                "discount": "8%",
                "mrp": "₹ 1,190.00",
                "price": "₹ 1,094.00",
                "id": "tawa5"
            }, {
                "name": "Prestige Omega Deluxe Nonstick Aluminium Tawa",
                "icon": [TawaPBox, TawaP3],
                "brand": "Prestige",
                "watts": "0T 250MM",
                "discount": "8%",
                "mrp": "₹ 1,010 .00",
                "price": "₹ 929.00",
                "id": "tawa5"
            }, {
                "name": "Lakshmi Tawa",
                "icon": TawaP1,
                "brand": "Lakshmi",
                "watts": "2.7 MM",
                "discount": "20%",
                "mrp": "₹ 450.00",
                "price": "₹ 360.00"
            }, {
                "name": "Lakshmi Tawa",
                "icon": TawaP1,
                "brand": "Lakshmi",
                "watts": "3.7 MM",
                "discount": "20%",
                "mrp": "₹ 600.00",
                "price": "₹ 480.00"
            }, {
                "name": "Lakshmi Tawa",
                "icon": [TawaP2, TawaPOuter],
                "brand": "Lakshmi",
                "watts": "4 MM",
                "discount": "35%",
                "mrp": "₹ 920.00",
                "price": "₹ 598.00",
                "id": "tawa1"
            }, {
                "name": "Lakshmi Tawa",
                "icon": [TawaP2, TawaPOuter],
                "brand": "Lakshmi",
                "watts": "5 MM",
                "discount": "30%",
                "mrp": "₹ 1,060 .00",
                "price": "₹ 742.00",
                "id": "tawa2"
            }]
        },
        {
            "name": "Mixer Grinder",
            "icon": Mixer,
            "products": [{
                "name": "Hydro",
                "icon": Mixer,
                "brand": "HAVELLS",
                "watts": "750W 3 jars",
                "discount": "24%",
                "mrp": "₹ 6,500.00",
                "price": "₹ 4,940.00",
                "availableColors": ["grey", "red"]
            }, {
                "name": "Hydro",
                "icon": MP1,
                "brand": "HAVELLS",
                "watts": "750W 4 jars",
                "discount": "22%",
                "mrp": "₹ 6,995.00",
                "price": "₹ 5,456.00"
            }, {
                "name": "Power Hunk",
                "icon": MP2,
                "brand": "HAVELLS",
                "watts": "800W 3 jars",
                "discount": "25%",
                "mrp": "₹ 7,000.00",
                "price": "₹ 5,250.00"
            }, {
                "name": "Hexo",
                "icon": MP3,
                "brand": "HAVELLS",
                "watts": "1000W 4 jars",
                "discount": "30%",
                "mrp": "₹ 10,250.00",
                "price": "₹ 7,175.00"
            }, {
                "name": "ASPRO",
                "icon": MP4,
                "brand": "HAVELLS",
                "watts": "500W 3 jars",
                "discount": "30%",
                "mrp": "₹ 4,800.00",
                "price": "₹ 3,360.00"
            }, {
                "name": "Questa",
                "icon": MP5,
                "brand": "Crompton",
                "watts": "750W 3 jars",
                "discount": "20%",
                "mrp": "₹ 3,500.00",
                "price": "₹ 2,800.00"
            }, {
                "name": "Qube",
                "icon": MP6,
                "brand": "Crompton",
                "watts": "750W 3 jars",
                "discount": "12%",
                "mrp": "₹ 4,500.00",
                "price": "₹ 3,960.00"
            }, {
                "name": "Vidiem Victor",
                "icon": MP7,
                "brand": "Vidiem",
                "watts": "600W 3 jars",
                "discount": "25%",
                "mrp": "₹ 4,800.00",
                "price": "₹ 3,600.00"
            }, {
                "name": "Preethi Crown MG-205 Mixer Grinder",
                "icon": MP8,
                "brand": "Preethi",
                "watts": "500 watt, White/Purple, 3 Jars",
                "discount": "25%",
                "warranty": "5 yr Motor Warranty & Lifelong Free Service",
                "mrp": "₹ 4,199.00",
                "price": "₹ 3,149.00"
            }, {
                "name": "Preethi Xpro Duo MG 198 Mixer Grinder",
                "icon": MP9,
                "brand": "Preethi",
                "watts": "1300W",
                "discount": "14%",
                "mrp": "₹ 10,391.00",
                "price": "₹ 8,936.00"
            }, {
                "name": "Preethi Crown Plus MG-258 Mixer Grinder",
                "icon": MP10,
                "brand": "Preethi",
                "watts": "600 Watt, White/Purple, 4 Jars - Super Extractor Juicer Jar",
                "discount": "25%",
                "warranty": "5 yr Motor Warranty & Lifelong Free Service",
                "mrp": "₹ 5,279.00",
                "price": "₹ 3,959.00"
            }, {
                "name": "Preethi Blue Leaf",
                "icon": MP11,
                "brand": "Preethi",
                "discount": "22%",
                "mrp": "₹ 8,915.00",
                "price": "₹ 6,953.00"
            }, {
                "name": "Preethi Steel Supreme MG-208 mixer grinder",
                "icon": MP12,
                "brand": "Preethi",
                "watts": "750 watt (Silver/ Black), 4 jars - Super Extractor juicer Jar",
                "discount": "28%",
                "warranty": "5yr Warranty & Lifelong Free Service",
                "mrp": "₹ 10,508.00",
                "price": "₹ 7,565.00"
            }
            ]
        },
        {
            "name": "Ceiling Fan",
            "icon": CeilingFan,
            "products": [{
                "name": "Energy Saving Fan",
                "icon": CeilingFan,
                "brand": "HAVELLS",
                "discount": "10%",
                "mrp": "₹ 4,500.00",
                "price": "₹ 4,050.00",
                "availableColors": ["#5f5154", "#cdc2a3", "#fff"]
            }, {
                "name": "REO Fan",
                "icon": FP1,
                "brand": "HAVELLS",
                "discount": "26%",
                "mrp": "₹ 2,700.00",
                "price": "₹ 1,998.00",
                "availableColors": ["#6a3c3e", "#f9e7ba", "#3b4165", "#423834"]
            }, {
                "name": "Crompton SUREBREEZE HILLBRIZ",
                "icon": [FP2, FP21, FP22, FP23],
                "brand": "Crompton",
                "discount": "15%",
                "watts": "1200 mm (48 inch) Ceiling Fan (Brown) Star rated energy efficient fans",
                "mrp": "₹ 2,600.00",
                "price": "₹ 2,210.00",
                "id": "fp2"
            }, {
                "name": "Crompton Aura",
                "icon": FP3,
                "brand": "Crompton",
                "discount": "10%",
                "watts": "1200 mm (48 inch) High Speed Decorative Ceiling Fan (Ivory)",
                "mrp": "₹ 3,700.00",
                "price": "₹ 3,330.00"
            }, {
                "name": "Crompton ARIYABRIZ",
                "icon": FP4,
                "brand": "Crompton",
                "discount": "15%",
                "watts": "Prime 0.6M Coral Gold 4 Blades Ceiling Fan",
                "mrp": "₹ 2,600.00",
                "price": "₹ 2,210.00"
            }, {
                "name": "USHA Swift",
                "icon": FP5,
                "brand": "USHA",
                "discount": "15%",
                "watts": "1200 mm Ceiling Fan (Rich Brown)",
                "mrp": "₹ 2,275.00",
                "price": "₹ 1,933.00"
            }, {
                "name": "Usha Bloom Magnolia",
                "icon": FP6,
                "brand": "USHA",
                "discount": "12%",
                "mrp": "₹ 4,110.00",
                "price": "₹ 3,616.00"
            }, {
                "name": "Mist Air ICY ",
                "icon": FP7,
                "brand": "USHA",
                "discount": "27%",
                "mrp": "₹ 3,500.00",
                "price": "₹ 2,555.00"
            }]
        },
        {
            "name": "Table Fan",
            "icon": TableFan,
            "products": [{
                "name": "Table Fan",
                "icon": TableFan,
                "brand": "Crompton",
                "discount": "20%",
                "mrp": "₹ 3,149.00",
                "price": "₹ 2,519.00"
            }, {
                "name": "Crompton HighSpeed Torpedo",
                "icon": TFP1,
                "brand": "Crompton",
                "discount": "10%",
                "mrp": "₹ 3,500.00",
                "price": "₹ 3,150.00"
            }, {
                "name": "Mist Air ICY",
                "icon": TFP2,
                "brand": "USHA",
                "discount": "25%",
                "mrp": "₹ 3,000.00",
                "price": "₹ 2,250.00"
            }, {
                "name": "Mist Air ICY",
                "icon": TFP3,
                "brand": "USHA",
                "discount": "20%",
                "mrp": "₹ 4,125.00",
                "price": "₹ 3,300.00"
            }, {
                "name": "Crompton HighSpeed Torpedo",
                "icon": TFP4,
                "brand": "Crompton",
                "discount": "20%",
                "mrp": "₹ 4,700.00",
                "price": "₹ 3,760.00"
            }, {
                "name": "Crompton HighSpeed Torpedo",
                "icon": TFP5,
                "brand": "Crompton",
                "discount": "15%",
                "mrp": "₹ 4,500.00",
                "price": "₹ 3,825.00"
            }, {
                "name": "Crompton Storm",
                "icon": TFP6,
                "brand": "Crompton",
                "discount": "35%",
                "mrp": "₹ 6,500.00",
                "price": "₹ 4,225.00"
            }]
        },
        {
            "name": "Bero",
            "icon": Bero,
            "products": [{
                "name": "Normal Bero",
                "icon": [BP1, BP2],
                "watts": "45KG",
                "discount": "13%",
                "mrp": "₹ 10,500.00",
                "price": "₹ 9,135.00",
                "id": "p1",
                "availableColors": ["#0a65b8", "#d4275d", "#2d8d87", "#5d3a9e"]
            }, {
                "name": "Glass Bero",
                "icon": [BP3, BP31],
                "watts": "45KG",
                "discount": "18%",
                "mrp": "₹ 12,500.00",
                "price": "₹ 10,250.00",
                "availableColors": ["#0a65b8", "#d4275d", "#2d8d87", "#5d3a9e"],
                "id":"bero-p2"
            }]
        },
        {
            "name": "Cot",
            "icon": Cot,
            "products": [{
                "name": "Design Cot",
                "icon": Cot,
                "watts": "48Size",
                "discount": "21%",
                "mrp": "₹ 9,500.00",
                "price": "₹ 7,505.00"
            }, {
                "name": "Cam Cot",
                "icon": COTP1,
                "watts": "1 Person and 2 Ring",
                "discount": "25%",
                "mrp": "₹ 2,500.00",
                "price": "₹ 1,875.00"
            }, {
                "name": "Cam Cot",
                "icon": [COTP21, COTP22, COTP23, COTP24],
                "watts": "2 Persons and 2 Ring",
                "discount": "25%",
                "mrp": "₹ 3,000.00",
                "price": "₹ 2,250.00",
                "id": "cot2"
            }]
        },
        {
            "name": "Bed",
            "icon": Bed,
            "products": [{
                "name": "Cirrus Basic 4",
                "icon": Bed,
                "watts": "Size/Dimension 78x48",
                "discount": "10%",
                "mrp": "₹ 6,902.00",
                "price": "₹ 6,211.00",
                "warranty":"48Month Warranty"
            }, {
                "name": "Cirrus Basic 5",
                "icon": Bed,
                "watts": "Size/Dimension 78x48",
                "discount": "3%",
                "mrp": "₹ 9,681.00",
                "price": "₹ 6,771.00",
                "warranty":"48Month Warranty"
            }]
        },
        {
            "name": "Table",
            "icon": StudyTable,
            "products": [{
                "name": "Wood Laminated Table",
                "icon": [TP1, TP11],
                "watts": "4*3 Feet",
                "discount": "15%",
                "mrp": "₹ 2,700.00",
                "price": "₹ 2,295.00",
                "id": "tp1",
            }, {
                "name": "Wood Laminated Table",
                "icon": TP2,
                "watts": "4*2 Feet",
                "discount": "21%",
                "mrp": "₹ 2,500.00",
                "price": "₹ 1,975.00"
            }, {
                "name": "Wood Laminated Table",
                "icon": TP3,
                "watts": "3*2 Feet",
                "discount": "15%",
                "mrp": "₹ 2,200.00",
                "price": "₹ 1,870.00"
            }, {
                "name": "Teacher table & kalla table",
                "icon": TP4,
                "watts": "4*2 Feet",
                "discount": "11%",
                "mrp": "₹ 4,000.00",
                "price": "₹ 3,560.00"
            }, {
                "name": "Table Heavy",
                "icon": TP5,
                "watts": "4*2 Feet",
                "discount": "6%",
                "mrp": "₹ 4,950.00",
                "price": "₹ 4,653.00"
            }]
        },
        {
            "name": "Chair",
            "icon": Chair,
            "products": [{
                "name": "VV National",
                "icon": [CP11, CP12, CP13, CP14],
                "discount": "12%",
                "mrp": "₹ 450.00",
                "price": "₹ 396.00",
                "id": "chp1",
            }, {
                "name": "VV National Vachram",
                "icon": [CHP3, CHP2],
                "discount": "12%",
                "mrp": "₹ 750.00",
                "price": "₹ 660.00",
                "id": "chp2"
            }, {
                "name": "VV National EC",
                "icon": CHP4,
                "discount": "12%",
                "mrp": "₹ 850.00",
                "price": "₹ 756.00"
            }, {
                "name": "VV National mayor",
                "icon": Chair,
                "discount": "16%",
                "mrp": "₹ 900.00",
                "price": "₹ 756.00"
            }]
        },
        {
            "name": "Steel Rack Stand",
            "icon": SteelRack,
            "products": [{
                "name": "Heavy Steel Rack",
                "icon": SteelRack,
                "watts": "5 layer steel rack",
                "discount": "20%",
                "mrp": "₹ 2,950.00",
                "price": "₹ 2,360.00"
            }, {
                "name": "Normal Steel Rack",
                "icon": SRP1,
                "watts": "5 layer steel rack",
                "discount": "17%",
                "mrp": "₹ 1,750.00",
                "price": "₹ 1,452.00"
            }]
        },
        {
            "name": "TV Stand",
            "icon": TVStand,
            "products": [{
                "name": "TV Stand Wood and Steel",
                "icon": TVP1,
                "discount": "17%",
                "mrp": "₹ 1,550.00",
                "price": "₹ 1,286.00"
            }, {
                "name": "TV Stand with wheels",
                "icon": TVP2,
                "discount": "21%",
                "mrp": "₹ 1,950.00",
                "price": "₹ 1,540.00"
            }]
        },
        {
            "name": "Dressing Table",
            "icon": DressingTable,
            "products": [{
                "name": "Dressing Table",
                "icon": [DTP1, DTP2, DTP3],
                "discount": "20%",
                "mrp": "₹ 6,500.00",
                "price": "₹ 5,200.00",
                "id": "dt1"
            }, {
                "name": "Mini Dressing Table",
                "icon": DTP4,
                "discount": "15%",
                "mrp": "₹ 3,500.00",
                "price": "₹ 2,975.00"
            }]
        },
        {
            "name": "Iron Box",
            "icon": IronBox,
            "products": [{
                "name": "SPARLE",
                "icon": IronBox,
                "brand": "HAVELLS",
                "watts": "1250W",
                "discount": "44%",
                "mrp": "₹ 3,250.00",
                "price": "₹ 1,820.00"
            }, {
                "name": "HAWK",
                "icon": IBP1,
                "brand": "HAVELLS",
                "watts": "1100W",
                "discount": "25%",
                "mrp": "₹ 1,745.00",
                "price": "₹ 1,308.00"
            }, {
                "name": "Aquilla",
                "icon": IBP2,
                "brand": "HAVELLS",
                "watts": "1000W",
                "discount": "13%",
                "mrp": "₹ 1,050.00",
                "price": "₹ 913.00"
            }, {
                "name": "Czar",
                "icon": IBP3,
                "brand": "HAVELLS",
                "watts": "1000W",
                "discount": "18%",
                "mrp": "₹ 1,110.00",
                "price": "₹ 902.00"
            }, {
                "name": "insta",
                "icon": IBP4,
                "brand": "HAVELLS",
                "watts": "600W",
                "discount": "17%",
                "mrp": "₹ 845.00",
                "price": "₹ 701.00"
            }, {
                "name": "Crompton RD",
                "icon": IBP5,
                "brand": "Crompton",
                "watts": "750W",
                "discount": "12%",
                "mrp": "₹ 750.00",
                "price": "₹ 660.00"
            }, {
                "name": "Crompton Brio",
                "icon": IBP6,
                "brand": "Crompton",
                "watts": "1000W",
                "discount": "20%",
                "mrp": "₹ 1,100.00",
                "price": "₹ 880.00"
            }, {
                "name": "Usha Iron Box",
                "icon": IBP7,
                "brand": "USHA",
                "watts": "1.75 kg",
                "discount": "10%",
                "mrp": "₹ 1,990.00",
                "price": "₹ 1,791.00",
                "warranty":"2 years warranty"
            }]
        },
        {
            "name": "Induction Stove",
            "icon": Induction,
            "products": [{
                "name": "Insta Cook TC 16",
                "icon": Induction,
                "brand": "HAVELLS",
                "watts": "1600W",
                "discount": "25%",
                "mrp": "₹ 5,395.00",
                "price": "₹ 4,046.00"
            }, {
                "name": "Insta Cook OT",
                "icon": INSP1,
                "brand": "HAVELLS",
                "watts": "1200W",
                "discount": "32%",
                "mrp": "₹ 4,625.00",
                "price": "₹ 3,145.00"
            }, {
                "name": "Prestige Xpress Induction",
                "icon": INSP2,
                "brand": "Prestige",
                "discount": "25%",
                "mrp": "₹ 2,895.00",
                "price": "₹ 2,171.00"
            }, {
                "name": "Prestige Rio Induction",
                "icon": INSP3,
                "brand": "Prestige",
                "discount": "15%",
                "mrp": "₹ 2.995.00",
                "price": "₹ 2,545.00"
            }, {
                "name": "Prestige Induction",
                "icon": INSP4,
                "brand": "Prestige",
                "discount": "25%",
                "mrp": "₹ 3,645.00",
                "price": "₹ 2,733.00"
            }, {
                "name": "V Guard VIC07 V3",
                "icon": INSP6,
                "brand": "V Guard",
                "discount": "22%",
                "mrp": "₹ 3,770.00",
                "price": "₹ 2,940.00",
                "watts":" ",
                "warranty":"2 Years Warranty"
            }, {
                "name": "V Guard VIC10 V3",
                "icon": INSP5,
                "brand": "V Guard",
                "discount": "20%",
                "mrp": "₹ 4,470.00",
                "price": "₹ 3,576.00",
                "watts":" ",
                "warranty":"1 Year Warranty"
            }]
        },
        {
            "name": "Stove",
            "icon": Stove,
            "products": [{
                "name": "Raj Surya LPG stove",
                "icon": StoveP1,
                "brand": "Raj Surya",
                "watts": "2 Burners",
                "discount": "25%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 2,395.00",
                "price": "₹ 1,796.00"
            }, {
                "name": "Surya Flame",
                "icon": StoveP2,
                "brand": "Surya Flame",
                "watts": "2 Burners",
                "discount": "25%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 2,395.00",
                "price": "₹ 1,796.00"
            }, {
                "name": "Butterfly rhino",
                "icon": StoveP3,
                "brand": "Butterfly rhino",
                "watts": "2 Burners",
                "discount": "40%",
                "warranty": "3 Years Warranty",
                "mrp": "₹ 4,360.00",
                "price": "₹ 2,616.00"
            }, {
                "name": "Butterfly",
                "icon": StoveP4,
                "brand": "Butterfly",
                "watts": "2 Burners with Glass",
                "discount": "50%",
                "warranty": "5 Years Warranty",
                "mrp": "₹ 7,019.00",
                "price": "₹ 3,509.00"
            }, {
                "name": "Butterfly",
                "icon": StoveP5,
                "brand": "Butterfly",
                "watts": "3 Burners with Glass",
                "discount": "50%",
                "warranty": "5 Years Warranty",
                "mrp": "₹ 10,149.00",
                "price": "₹ 5,074.00"
            }, {
                "name": "Sunflame",
                "icon": StoveP6,
                "brand": "Butterfly",
                "watts": "3 Burners with Glass",
                "discount": "50%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 10,149.00",
                "price": "₹ 5,074.00"
            }, {
                "name": "Prestige",
                "icon": StoveP7,
                "brand": "Prestige",
                "watts": "2 Burners",
                "discount": "20%",
                "mrp": "₹ 3,595.00",
                "price": "₹ 2,876.00"
            }, {
                "name": "Agni havey",
                "icon": StoveP8,
                "brand": "Prestige",
                "watts": "2 Burners",
                "discount": "15%",
                "mrp": "₹ 4,495.00",
                "price": "₹ 3,820.00"
            }, {
                "name": "Prestige IRIS Black Top",
                "icon": StoveP9,
                "brand": "Prestige",
                "watts": "2 Burners with glass top",
                "discount": "25%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 5,845.00",
                "price": "₹ 4,383.00"
            }, {
                "name": "Prestige IRIS Black Top",
                "icon": StoveP10,
                "brand": "Prestige",
                "watts": "3 Burners with glass",
                "discount": "30%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 7,845.00",
                "price": "₹ 5,491.00"
            }, {
                "name": "Preeti",
                "icon": StoveP11,
                "brand": "Preeti",
                "watts": "2 Burners with glass",
                "discount": "35%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 6,665.00",
                "price": "₹ 4,432.00"
            }, {
                "name": "Preeti",
                "icon": StoveP12,
                "brand": "Preeti",
                "watts": "3 Burners with glass",
                "discount": "33%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 8,825.00",
                "price": "₹ 5,912.00"
            }, {
                "name": "Preeti",
                "icon": StoveP13,
                "brand": "Preeti",
                "watts": "3 Burners",
                "discount": "18%",
                "warranty": "2 Years Warranty",
                "mrp": "₹ 6,755.00",
                "price": "₹ 5,539.00"
            }]
        },
        {
            "name": "Electric Kettle",
            "icon": ElectricKettle,
            "products": [{
                "name": "Aqua Plus",
                "icon": ElectricKettle,
                "brand": "HAVELLS",
                "watts": "1500W 1.2L",
                "discount": "25%",
                "mrp": "₹ 3,280.00",
                "price": "₹ 2,460.00"
            },{
                "name": "Prestige Electric Kettle",
                "icon": EKP1,
                "brand": "Prestige",
                "discount": "55%",
                "mrp": "₹ 1,445.00",
                "price": "₹ 1,228.00",
                "warranty":"1 Year"
            }]
        },
        {
            "name": "Hair Dryer",
            "icon": HairDryer,
            "products": [{
                "name": "HD 3152",
                "icon": HairDryer,
                "brand": "HAVELLS",
                "watts": "1200W",
                "discount": "12%",
                "mrp": "₹ 1,745.00",
                "price": "₹ 1,535.00"
            }, {
                "name": "HD 1901",
                "icon": HDP1,
                "brand": "HAVELLS",
                "watts": "1200W",
                "discount": "12%",
                "mrp": "₹ 1,495.00",
                "price": "₹ 1,315.00"
            }]
        },
        {
            "name": "Beard Trimmer",
            "icon": BeardTrimmer,
            "products": [{
                "name": "Corporate Beard Trimmer",
                "icon": BeardTrimmer,
                "brand": "HAVELLS",
                "discount": "18%",
                "mrp": "₹ 1,565.00",
                "price": "₹ 1,283.00",
                "availableColors": ["#2f3653", "red"]
            }]
        },
        {
            "name": "Water Heater",
            "icon": WaterHeater,
            "products": [{
                "name": "Water Heater",
                "icon": WHP1,
                "brand": "HAVELLS",
                "watts": "1000W",
                "discount": "12%",
                "mrp": "₹ 890.00",
                "price": "₹ 783.00"
            }, {
                "name": "Water Heater",
                "icon": WaterHeater,
                "brand": "Crompton",
                "watts": "1000W",
                "discount": "20%",
                "mrp": "₹ 740.00",
                "price": "₹ 592.00"
            }, {
                "name": "Water Heater",
                "icon": WHP2,
                "brand": "Crompton",
                "watts": "1000W",
                "discount": "15%",
                "mrp": "₹ 910.00",
                "price": "₹ 773.00"
            }, {
                "name": "Electric Immersion Water Heater - Auto",
                "icon": WHP3,
                "brand": "HAVELLS",
                "watts": "1000W",
                "discount": "15%",
                "mrp": "₹ 985.00",
                "price": "₹ 837.00",
                "warranty":"2 Years Warranty"
            }]
        },
        {
            "name": "Flask",
            "icon": Flask,
            "products": [{
                "name": "Butterfly Flask",
                "icon": [FLASKP11,FLASKP12,FLASKP13,FLASKP14,FLASKP15],
                "brand": "Butterfly",
                "watts": "500 ML",
                "discount": "10%",
                "mrp": "₹ 950.00",
                "price": "₹ 855.00",
                "id":"flaskp1"
            },{
                "name": "Butterfly Flask",
                "icon": [FLASKP11,FLASKP12,FLASKP13,FLASKP14,FLASKP15],
                "brand": "Butterfly",
                "watts": "750 ML",
                "discount": "20%",
                "mrp": "₹ 1,231.00",
                "price": "₹ 984.00",
                "id":"flaskp2"
            },{
                "name": "Butterfly Flask",
                "icon": [FLASKP11,FLASKP12,FLASKP13,FLASKP14,FLASKP15],
                "brand": "Butterfly",
                "watts": "1000 ML",
                "discount": "15%",
                "mrp": "₹ 1,456.00",
                "price": "₹ 1,237.00",
                "id":"flaskp3"
            },{
                "name": "Milton Flask",
                "icon": [FLASKP21,FLASKP22,FLASKP23,FLASKP24,FLASKP25],
                "brand": "Milton",
                "watts": "500 ML",
                "discount": "10%",
                "mrp": "₹ 850.00",
                "price": "₹ 765.00",
                "id":"flaskp4"
            },{
                "name": "Milton Flask",
                "icon": [FLASKP21,FLASKP22,FLASKP23,FLASKP24,FLASKP25],
                "brand": "Milton",
                "watts": "750 ML",
                "discount": "15%",
                "mrp": "₹ 1,100.00",
                "price": "₹ 935.00",
                "id":"flaskp5"
            },{
                "name": "Milton Flask",
                "icon": [FLASKP21,FLASKP22,FLASKP23,FLASKP24,FLASKP25],
                "brand": "Milton",
                "watts": "1000 ML",
                "discount": "10%",
                "mrp": "₹ 1,200.00",
                "price": "₹ 1,080.00",
                "id":"flaskp6"
            }]
        },
        {
            "name": "Air Cooler",
            "icon": ACP1,
            "products": [{
                "name": "Crompton Air Cooler",
                "icon": ACP1,
                "discount": "26%",
                "mrp": "₹ 8,000.00",
                "price": "₹ 5,920.00",
                "watts":"16LR",
                "brand": "Crompton",
                "warranty": "1 year Warranty"
            }, {
                "name": "Crompton Air Cooler",
                "icon": ACP2,
                "discount": "23%",
                "mrp": "₹ 9,790.00",
                "price": "₹ 7,538.00",                
                "brand": "Crompton",
                "watts":"54LR",
                "brand": "Crompton",
                "warranty": "1 year Warranty"
            },{
                "name": "Butterfly Windy Tower Fan",
                "icon": ACP3,
                "discount": "40%",
                "mrp": "₹ 5,830.00",
                "price": "₹ 3,498.00",
                "brand":"Butterfly",
                "warranty": "1 year Warranty"
            },{
                "name": "V Guard Tower Fan",
                "icon": ACP4,
                "discount": "20%",
                "mrp": "₹ 4,890.00",
                "price": "₹ 3,912.00",
                "brand":"V Guard",
                "warranty": "1 year Warranty"
            }]
        }
    ]
    const [showProduceDetails, setShowProductDetails] = useState(false)
    const [produceDetails, setProductDetails] = useState(null)
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [activePage, setActivePage] = useState(null)
    const onProductDetails = (p, b) => {
        setProductDetails(p)
        setShowProductDetails(b)
        setActivePage(p.name)
    }
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (event, p) => {
        setAnchorElNav(null);
        setProductDetails(null)
        setActivePage(p.name)
        setTimeout(() => {
            setProductDetails(p)
        }, 100)
    };
    return (
        <div className={`${showProduceDetails ? "app-product p0" : "app-product"}`} id="products" >
            {
                showProduceDetails ? <div className="product-list-view">
                    <div className="product-list">
                        <AppBar>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="a"
                                        href="/"
                                        sx={{
                                            mr: 2,
                                            display: { xs: 'none', md: 'flex' },
                                            fontFamily: 'app-font-Regular',
                                            fontWeight: 700,
                                            letterSpacing: '.3rem',
                                            color: 'inherit',
                                            textDecoration: 'none',
                                            padding: "15px 0px",
                                            boxSizing: "border-box"
                                        }}
                                    >
                                        Products
                                    </Typography>

                                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            color="inherit"
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                            sx={{
                                                display: { xs: 'block', md: 'none' },
                                            }}
                                            className="mobile-header"
                                        >
                                            {products.map((page, i) => (
                                                <MenuItem key={i} onClick={(e) => { handleCloseNavMenu(e, page) }}>
                                                    <Typography textAlign="center" className={activePage === page.name ? "product-list-active" : ""}>
                                                        {page.icon.length ? <img src={page.icon} className="product-list-navicon" /> : null}
                                                        {page.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        {products.map((page, i) => (
                                            <Button
                                                key={i}
                                                onClick={(e) => { handleCloseNavMenu(e, page) }}
                                                sx={{ my: 2, color: 'white', display: 'block' }}
                                                style={{ textTransform: "capitalize" }}
                                                className={activePage === page.name ? "product-list-active" : ""}
                                            >
                                                {page.icon.length ? <img src={page.icon} className="product-list-navicon" /> : null}{page.name}
                                            </Button>
                                        ))}
                                    </Box>
                                </Toolbar>
                            </Container>
                        </AppBar>
                    </div>
                    {produceDetails ? <div className="product-list-detail">
                        <ProductDetails productData={produceDetails} />
                    </div> : "Loading..."}
                </div> : <React.Fragment>
                    <h1 className="title-txt">Products</h1>
                    <div className="product-icon-wrap">
                        {products.map((p, i) => {
                            return <div key={i} className="product-icon-txt" onClick={() => { onProductDetails(p, true) }}>
                                <div className="product-icon">
                                    {p.icon.length ? <img src={p.icon} /> : null}
                                </div>
                                <h3 className="product-txt">{p.name}</h3>
                            </div>
                        })}
                    </div>
                    <Button variant="contained" onClick={() => { onProductDetails(products[0], true) }}>View More Details</Button>
                </React.Fragment>
            }


        </div>
    )
}
export default Products;